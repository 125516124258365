import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TestimonialsSection = () => {

  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Testimonials</h2>
        </div>

        <div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-amila.jpg" className="testimonial-img" alt="Testimonial by Amila" /> */}
                <StaticImage src="../images/testimonials/testimonials-amila.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Amila" formats={["auto", "webp"]} quality={80}/>
                <h3>Amila De Silva</h3>
                <h4>Senior Software Architect, 99x</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Kasun is widely regarded as a very dependable techy with a strong work ethic who is self-motivated, driven, and obsessed with doing things right. He is often recognized as a technology consultant within the team and brings a vast amount of .Net, Azure, and DevOps skills to the discussion table.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-ricardo.jpg" className="testimonial-img" alt="Testimonial by Ricardo" /> */}
                <StaticImage src="../images/testimonials/testimonials-ricardo.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Ricardo" formats={["auto", "webp"]} quality={80}/>
                <h3>Ricardo Rodriguez</h3>
                <h4>Tech Lead, Trintech</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I have seen Kasun not only excel at the current practices of DevOps and Azure but also quickly adapt and adopt new trends and technology that emerge in this ever-changing industry. Kasun's enthusiasm over quality, as well as his knowledge sharing and communication skills, is something to be desired in any professional
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-sajee.jpg" className="testimonial-img" alt="Testimonial by Sajeetharan" /> */}
                <StaticImage src="../images/testimonials/testimonials-sajee.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Sajeetharan" formats={["auto", "webp"]} quality={80}/>
                <h3>Sajeetharan Sinnathurai</h3>
                <h4>Senior Program Manager - Azure Cosmos DB, Microsoft</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Kasun is undoubtedly one of the most talented individual and an excellent colleague who possess an analytical approach, technically sound and willing to take up challenges at any time. He has the ability take control of a situation and guide a team towards a solution.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-anushka.jpg" className="testimonial-img" alt="Testimonial by Anushka" /> */}
                <StaticImage src="../images/testimonials/testimonials-anushka.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Anushka" formats={["auto", "webp"]} quality={80}/>
                <h3>Anushka Wickramaratne</h3>
                <h4>Quality Assurance Architect, Cambio Healthcare Systems</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I strongly believe in Kasun's hard-working attitude. He is not just a hard worker, but smart worker as well. His technical competency is unbelievable when compared with his industry experience.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-richard.jpg" className="testimonial-img" alt="Testimonial by Richard" /> */}
                <StaticImage src="../images/testimonials/testimonials-richard.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Richard" formats={["auto", "webp"]} quality={90}/>
                <h3>Richard Halvorsen</h3>
                <h4>Senior Manager - Software Engineering, Worldlike Global</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Kasun is one of the most talented persons that I have ever come across. Whatever you throw at him, whether it is a technical challenge or a meeting with a potential customer, he always delivers.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-inoka.jpg" className="testimonial-img" alt="Testimonial by Inoka" /> */}
                <StaticImage src="../images/testimonials/testimonials-inoka.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by Inoka" formats={["auto", "webp"]} quality={80}/>
                <h3>Inoka Silva</h3>
                <h4>Associate Software Architect, Mitra Innovation</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Kasun is one of the best among all the team members I have ever worked with. Kasun is very passionate and has great vision for his work. His technical knowledge, capacity of understanding and learning any new technologies is excellent.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                {/* <img src="images/testimonials/testimonials-oscar.jpg" className="testimonial-img" alt="Testimonial by Oscar" /> */}
                <StaticImage src="../images/testimonials/testimonials-oscar.jpg" className="testimonial-img" layout="fullWidth" placeholder="blurred" alt="Testimonial by oscar" formats={["auto", "webp"]} quality={80}/>
                <h3>Oscar Elgquist</h3>
                <h4>Solution Owner, Adra</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Kasun's ability to handle complex problems and issues is unlike any I’ve seen before and his contributions made a dramatic increase in the productivity level for Adra.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>

          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  );
}

export default TestimonialsSection;