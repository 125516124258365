import React from 'react';

const ResumeSection = () => {
  return (
    <section id="resume" className="resume">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Resume</h2>
          <p>Kasun Kodagoda graduated with a first-class Degree in Information & Communication Technology. A Dynamic Individual Technical Depth, Collaboration, Team Work and Innovation. Working as a part of the R&D Team based on Sri Lanka working with ISVs in Norwegian Region. Experienced in Agile Methodologies with high caliber technical silks</p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <h3 className="resume-title">Sumary</h3>
            <div className="resume-item pb-0">
              <h4>Kasun Kodagoda</h4>
              <p><em>Passionate and innovative developer with {`${new Date().getFullYear() - 2015}+`} years of experience in the industry building highly scalable distributed business applications. With hands-on experience in cloud and web technologies with expertise in DevOps and Security practices.</em></p>
              <ul>
                <li>Gampaha, Sri Lanka</li>
                <li>(+94)717 673 839</li>
                <li>hello@kasunkodagoda.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>Bachelor of Science in Information & Communication Technology</h4>
              <h5>2010 - 2015</h5>
              <p><em>Rajarata University of Sri Lanka</em></p>
              <p>Completed the Bachelor of Science degree with a 3.72/4.00 GPA while contributing to many academic and non-academic activities as well as the tech community.</p>
            </div>
            <div className="resume-item">
              <h4>GCE Advanced Level - Biology Stream</h4>
              <h5>2000 - 2007</h5>
              <p><em>Bandaranayake College, Gampaha</em></p>
              <p>Studied for my Advanced Level examination in Biology Stream. Team leader of the IT Quiz team and represented in the Finals of IT Quiz 2006 conducted by UCSC (University of Colombo School of Computing)</p>
            </div>

            <h3 className="resume-title">Honors & Awards</h3>
            <div className="resume-item">
              <h4>Excellence Award for Outstanding Initiative Contribution 2016</h4>
              <p><em>99X, Sri Lanka</em></p>
              {/* <p>The award was given for contributing to multiple company initiatives during year 2016.</p> */}
            </div>
            <div className="resume-item">
              <h4>Excellence Award for Outstanding Initiative Contribution 2017</h4>
              <p><em>99X, Sri Lanka</em></p>
              {/* <p>The award was given for contributing to multiple company initiatives during year 2017.</p> */}
            </div>
            <div className="resume-item">
              <h4>Technology Committee Award for Driving Technology Road-map to Practice 2018</h4>
              <p><em>99X, Sri Lanka</em></p>
              {/* <p>Awarded for the contributions towards leadership, guidance driving the organization's technology road-map throughout the year 2018.</p> */}
            </div>
            <div className="resume-item">
              <h4>Excellence for Technology Leadership 2019 - Runner-up</h4>
              <p><em>99X, Sri Lanka</em></p>
              {/* <p>The award was given for contributing towards Technology Leadership at 99X Technology for the year 2019.</p> */}
            </div>
          </div>

          <div className="col-lg-6">
            <h3 className="resume-title">Professional Experience</h3>
            <div className="resume-item">
              <h4>Senior Technical Lead</h4>
              <h5>2015 - Present</h5>
              <p><em>99x, Sri Lanka</em></p>
              <ul>
                <li>Leading the DevOps movement within the product teams, with special focus on Continuous Integration, Continuous Delivery and Overall product quality.</li>
                <li>Subject matter expertise on Azure DevOps and Azure. Using industry best practices for creating build and release pipelines, Infrastructure as Code with Azure Resource Manager (ARM) templates, Azure resource management etc.</li>
                <li>Working as part of a 35 member team and leading a 6 member sub-team working on the Platform services for Adra Financial Close software suite.</li>
                <li>Working with ASP.Net Core, C#, Azure Cloud Services, Azure DevOps, Identity Server, SAML 2.0, Aurelia, TypeScript, Dynamics 365, Power Platform and other technologies to implement the Identity and Access Management platform and supporting APIs.</li>
                <li>Direct engagement with Scandinavian and US customers (ISV) for technical and non-technical discussions and decision making.</li>
              </ul>
            </div>
            <div className="resume-item">
              <h4>Founder, Developer and Designer</h4>
              <h5>2012 - Present</h5>
              <p><em>K2V Software Solutions</em></p>
              <ul>
                <li>K2V Software Solutions is an IT solutions provider specializes in creating provide functional & elegant Mobile App and Web Development for our clients.</li>
                <li>Designing and developing Web Applications, Web Designs, Mobile Applications.</li>
                <li>Specialized in Windows Phone Development, has experience developing over 15 applications available on Windows Phone Store and Windows Store.</li>
              </ul>
            </div>

            <h3 className="resume-title">Professional Certifications</h3>
            <div className="resume-item">
              <h4>AZ-400 - Microsoft Azure DevOps Solutions</h4>
              <h5>2018</h5>
              <p><em>Microsoft</em></p>
              <ul>
                <li>Design a DevOps Strategy</li>
                <li>Implement DevOps Development Processes</li>
                <li>Implement Continuous Integration & Continuous Delivery</li>
                <li>Implement Dependency Management, Application Infrastructure and Continuous Feedback</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default ResumeSection;