import React from 'react';

const HeroSection = () => {

  return(
    <section id="hero" className="d-flex flex-column justify-content-center">
      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <h1>Kasun Kodagoda</h1>
        <p>I'm a <span className="typed" data-typed-items="Developer, Designer, Tech Enthusiast, Consultant, Blogger, Content Creator"></span></p>
        <div className="social-links">
          <a href="https://twitter.com/kasun_kodagoda" className="twitter" target="_blank" rel="noreferrer" aria-label="Twitter Profile"><i className="bx bxl-twitter"></i></a>
          <a href="https://www.facebook.com/kasun.kodagoda" className="facebook" target="_blank" rel="noreferrer" aria-label="Facebook Profile"><i className="bx bxl-facebook"></i></a>
          <a href="https://www.linkedin.com/in/kasunkodagoda/" className="linkedin" target="_blank" rel="noreferrer" aria-label="LinkedIn Profile"><i className="bx bxl-linkedin"  target="_blank" aria-label="LinkedIn Profile"></i></a>
          <a href="https://github.com/kasunkv" className="github"><i className="bx bxl-github" target="_blank" rel="noreferrer" aria-label="GitHub Profile"></i></a>
          <a href="https://www.instagram.com/kasunkv/" className="instagram"><i className="bx bxl-instagram" target="_blank" rel="noreferrer" aria-label="Instagram Profile"></i></a>
          <a href="https://join.skype.com/invite/davmYpbWOmqw" className="google-plus"><i className="bx bxl-skype" target="_blank" rel="noreferrer" aria-label="Skype Contact"></i></a>
        </div>
      </div>
  </section>
  );
};

export default HeroSection;