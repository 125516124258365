import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import Helmet from 'react-helmet';

import Navbar from './navbar';
import Footer from './footer';
import HeroSection from './hero-section';
import AboutSection from './about-section';
import SkillsSection from './skills-section';
import ResumeSection from './resume-section';
import TestimonialsSection from './testimonials-section';
import ContactSection from './contact-section';
// import FactsSection from './facts-section';
// import PortfolioSection from './portfolio-section';
// import ServicesSection from './services-section';


const Layout = ({ children }) => {

  return (
    <>
      <i className="bi bi-list mobile-nav-toggle d-xl-none"></i>
      <Navbar />
      <HeroSection />
      <main id="main">
        {children}
        <AboutSection />
        {/* <FactsSection /> */}
        <SkillsSection />
        <ResumeSection />
        {/* <PortfolioSection /> */}
        {/* <ServicesSection /> */}
        <TestimonialsSection />
        <ContactSection />
      </main>
      <Footer />
      {/* <div id="preloader"></div> */}
      <button href="#" className="back-to-top d-flex align-items-center justify-content-center" aria-label="Up Button" alt="Up Button"><i className="bi bi-arrow-up-short"></i></button>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
