import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const AboutSection = () => {

  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>About</h2>
          <p>Working as a Senior Technical Lead at 99X working on the Microsoft stack and having {`${new Date().getFullYear() - 2015}+`} years of industry experience, Kasun is an Azure Cloud and Azure DevOps enthusiasts and contributes to few Open-Source projects on GitHub.</p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <StaticImage src="../images/profile-img.jpg" className="img-fluid" layout="fullWidth" placeholder="blurred" alt="Kasun Kodagoda" formats={["auto", "webp"]} quality={80}/>
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content">
            <h3>Developer &amp; Designer.</h3>
            <p></p>
            {/* <p className="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p> */}
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>8 August 1988</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>kasunkodagoda.com</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>(+94)717 673 839</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Gampaha, Sri Lanka</span></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>{new Date().getFullYear() - 1988}</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bachelor of Science</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>hello@kasunkodagoda.com</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                </ul>
              </div>
            </div>
            <p className="fst-italic">
            Outside of work, He likes to contribute to open-source projects on GitHub, mainly focusing on Azure Pipelines Extensions.  OWASP ZAP Scan, Slack Notification task are some of his popular Azure DevOps extension. Kasun is also a blogger where he writes about technical topics at K2V Academy, occasional gamer and creates YouTube videos on Azure, Azure DevOps and Gaming and loves creating Architectural and Interior Design visualizations when he finds time.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default AboutSection;