import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
    <div className="container">
      <h3>Kasun Kodagoda</h3>
      <p>Azure & DevOps Enthusiast | Open Source Contributor | Blogger | Speaker | Senior Technical Lead</p>
      <div className="social-links">
        <a href="https://twitter.com/kasun_kodagoda" className="twitter" target="_blank" rel="noreferrer" aria-label="Twitter Profile"><i className="bx bxl-twitter"></i></a>
        <a href="https://www.facebook.com/kasun.kodagoda" className="facebook" target="_blank" rel="noreferrer" aria-label="Facebook Profile"><i className="bx bxl-facebook"></i></a>
        <a href="https://www.linkedin.com/in/kasunkodagoda/" className="linkedin" target="_blank" rel="noreferrer" aria-label="LinkedIn Profile"><i className="bx bxl-linkedin"></i></a>
        <a href="https://github.com/kasunkv" className="github" target="_blank" rel="noreferrer" aria-label="GitHub Profile"><i className="bx bxl-github"></i></a>
        <a href="https://www.instagram.com/kasunkv/" className="instagram" target="_blank" rel="noreferrer" aria-label="Instagram Profile"><i className="bx bxl-instagram"></i></a>
        <a href="https://join.skype.com/invite/davmYpbWOmqw" className="google-plus" target="_blank" rel="noreferrer" aria-label="Skype Contact"><i className="bx bxl-skype"></i></a>
      </div>
      <div className="copyright">
        &copy; Copyright {new Date().getFullYear()} <strong><span>Kasun Kodagoda</span></strong>. All Rights Reserved
      </div>
      <div className="credits">
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>
  );
}
 
export default Footer;